import React from 'react';
import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import { I18nextProvider } from '@wix/wix-i18n-config';
import { initI18n } from '@wix/communities-blog-client-common';

import { connect } from '../../../common/components/runtime-context';
import ResponsiveListener from '../../../common/components/responsive-listener/responsive-listener';
import { getIsICUEnabled, getLanguage } from '../../../common/store/basic-params/basic-params-selectors';
import { getTranslations } from '../../../common/store/translations/translations-selectors';
import { withReduxStore } from '../../../common/components/runtime-context/with-redux-store';
import { getSectionUrl } from '../../../common/store/topology/topology-selectors';
import AppLoaded from '../../../common/components/app-loaded';
import CategoryMenu from '../category-menu';
import CategoryMenuNew from '../category-menu/category-menu-new';
import styles from './app-root.scss';
import { getIsCategoryMenuWidgetAdapterEnabled } from '../../aggregated-category-menu/selectors';

class AppRoot extends React.Component {
  getI18nConfig = () => {
    const { language, translations, isICUEnabled } = this.props;

    if (this.lastLanguage !== language) {
      this.i18nConfig = initI18n(language, translations, isICUEnabled);
      this.lastLanguage = language;
    }
    return this.i18nConfig;
  };

  render() {
    const { isCategoryMenuWidgetAdapterEnabled } = this.props;

    if (isCategoryMenuWidgetAdapterEnabled) {
      return (
        <ResponsiveListener>
          <div className={styles.baseStyles}>
            <CategoryMenuNew />
            <AppLoaded />
          </div>
        </ResponsiveListener>
      );
    }

    const i18n = this.getI18nConfig();

    return (
      <ResponsiveListener>
        <I18nextProvider i18n={i18n}>
          <div className={styles.baseStyles}>
            <CategoryMenu />
            <AppLoaded />
          </div>
        </I18nextProvider>
      </ResponsiveListener>
    );
  }
}

AppRoot.propTypes = {
  language: PropTypes.string,
  translations: PropTypes.object.isRequired,
  sectionUrl: PropTypes.string.isRequired,
  isCategoryMenuWidgetAdapterEnabled: PropTypes.bool.isRequired,
};

const mapRuntimeToProps = (state) => ({
  language: getLanguage(state),
  translations: getTranslations(state),
  sectionUrl: getSectionUrl(state),
  isCategoryMenuWidgetAdapterEnabled: getIsCategoryMenuWidgetAdapterEnabled(state),
  isICUEnabled: getIsICUEnabled(state),
});

export default flowRight(withReduxStore, connect(mapRuntimeToProps))(AppRoot);
